@import "../../index.scss";

.navbar {
    background: none;
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
}

.nav-links{

    align-self: center;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    text-align: center;
}

.nav-link {
    color:$mainBlack;
    text-transform:capitalize;
    text-decoration: none;
    font-size: 4 rem;
    transition: all 0.6s linear;
    &:hover {
        color: #881F21;
    }
}
.active {
    color: #881F21;

}
.button {
    background-color: #E3E6E8;
    border: solid;
    padding: 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 8px 10px;
   
  }
  
.button5 {border-radius: 15%;}
.button5:hover {background-color: #E7E1E1;}

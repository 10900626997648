.mainmenu {
    padding: 2 rem;
    border-radius: 0.2rem;
    grid-template-columns: auto 1fr;
    text-align: center;
    
}

.nav-links1{

    align-self: center;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    text-align: center;
   
}

#block2{
    height:auto;
    width:60%;
    float:center;
    border-color: none;
    
}

.mainmenu1:hover {
    background: rgb(203, 223, 223);
  }

  .mainmenu1 {border-radius: 6%;

}

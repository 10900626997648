*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

$mainBlack: #222;
$mainGreen: #82b340;
$mainWhite: #fff;
$mainGrey: #ececec;

body {
  background: $mainGrey;
  color: $mainBlack;
  font-family: 'Open Sans', sans-serif;
}

